import React, {
  cloneElement,
  isValidElement,
  ReactNode,
  useCallback,
  useMemo,
  VFC,
} from 'react'
import { trackEvent } from '../services/event-tracker'

export type WithAnalyticsProps = {
  event?: string
  params?: Record<string, unknown>
  tracker?: typeof trackEvent
  children: ReactNode
}

const wrapElement = (
  child: ReactNode,
  handler: (e?: React.MouseEvent) => void
): ReactNode => {
  if (Array.isArray(child)) return child.map((c) => wrapElement(c, handler))
  if (!isValidElement(child)) return child

  // merge our handler with the existing onClick prop if there is one
  let wrappedHandler = handler
  if ('onClick' in child.props) {
    const { onClick } = child.props
    wrappedHandler = (e?: React.MouseEvent) => {
      handler(e)
      onClick(e)
    }
  }
  return cloneElement(child, { onClick: wrappedHandler } as React.Attributes)
}

export const WithAnalytics: VFC<WithAnalyticsProps> = ({
  children,
  event,
  tracker = trackEvent,
  params = {},
}) => {
  const handleClick = useCallback(() => {
    if (event) {
      tracker(event, params)
    }
  }, [event, params, tracker])

  const wrapped = useMemo(
    () => wrapElement(children, handleClick),
    [children, handleClick]
  )

  return event ? <>{wrapped}</> : <>{children}</>
}
